// Libib Library
import React from 'react';
import styled from 'styled-components';
import Layout from '../layouts/Layout';
import SEO from '../components/SEO';
import { PageProps } from 'gatsby';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LibibEmbed = styled.iframe`
  width: 100%;
  height: calc(100vh - 120px);
`;

const Library: React.FC<PageProps> = function (props) {
  const { location } = props;

  return (
    <Layout>
      <SEO title="Library" path={location?.pathname} />
      <Wrapper>
        <LibibEmbed
          src="https://sdea.libib.com/"
          title="SDEA Library"
        ></LibibEmbed>
      </Wrapper>
    </Layout>
  );
};

export default Library;
